require('./bootstrap');

$( document ).ready(function() {

    $(document).ready(function() {
        let datatable = $('#myTable').DataTable( {
            processing: true,
            serverSide: true,
            // ajax:  "http://universia.test/api/registrations",
            ajax: {
                url: "http://universia.test/api/registrations",
                dataType: 'json',
                type: 'GET',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', api_key );
                },
            },
            columns: [
                {data: 'id', name: 'id', searchable: false},
                {data: 'firstname', name: 'firstname'},
                {data: 'lastname', name: 'lastname'},
                {data: 'email', name: 'email'},
                {data: 'phone', name: 'phone'},
                {data: 'etablissement', name: 'etablissement'},
                {
                    data: 'created_at',
                    name: 'created_at',
                    render: function (data, type, row) {
                        return window.moment(row.created_at).locale('fr').format('d MMMM YYYY, H:mm')
                    }
                },
                {
                    data:null,
                    defaultContent:" ",
                    searchable: false,
                    orderable: false,
                }
            ],
        } );


        datatable.on( 'init.dt', function () {
            let list = '<option value="">filtrez par établissement</option>';

            $.ajax({
                url: "http://universia.test/api/etablissements",
                method: "GET",
                data: {},
                dataType: "JSON",
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', api_key );
                },
            }).done(function (response) {

                response.forEach(function (el) {
                    if(el.etablissement !== null)
                    list += "<option value='"+el.etablissement+"'>"+el.etablissement+"</option>";
                })

                $("div#myTable_filter").append('<div style="margin: 0px 15px;" class="dataTables_length" id="myTable_length"><label>Etablissement <select name="myTable_length" aria-controls="myTable" class="etablissment_filter">'+list+'</select> </label></div>');

            }).fail(function (error) {
                console.log(error)
            });

        } )

        $(document).on('change', '.etablissment_filter', function (e) {
            datatable.columns( 5 ).search( this.value ).draw();
        })



    } );

});